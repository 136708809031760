// Smooth Scroll Browser Behavior
import smoothscroll from 'smoothscroll-polyfill';
import 'smoothscroll-anchor-polyfill';
import { Workbox } from "workbox-window";

smoothscroll.polyfill();

//Include files
import Tracking from './tracking';
import Push from './push';
import Globals from './globals';
import Offers from './offers';
import Stores from './stores';
import Register from './register';
import Locations from './locations';
import MessageThreads from './message-threads';
import Logs from './logs';

if ('serviceWorker' in navigator) {
    // serviceWorkerRegistration.unregister();
    // caches.keys().then(cacheNames => {
    //     cacheNames.forEach(cacheName => {
    //     caches.delete(cacheName);
    //     });
    // }).then(() => {
    //     serviceWorkerRegistration.register();
    // })

    window.addEventListener('load', () => {
        const wb = new Workbox('../../sw.js');

        wb.register();

        wb.addEventListener("waiting", event => {
        wb.addEventListener("controlling", event => {
            window.location.reload();
        });

        wb.messageSW({ type: "SKIP_WAITING" });
    });
    });

    self.addEventListener('push', function(event) {
        const pushData = event.data.json();
        const promiseChain = self.registration.showNotification(pushData.title, {
            body: pushData.body,
            icon: "https://www.gotojoes.com/dist/img/icons/icon-96x96.png",
        });

        event.waitUntil(promiseChain);
    });
}

document.addEventListener("DOMContentLoaded", () => {
    new Tracking();
    new Push();
    new Globals();
    new Offers();
    new Stores();
    new Register();
    new Locations();
    new MessageThreads();
    new Logs();
});
