import TimeMe from 'timeme.js';

const visibleAds = new Set();
let previouslyVisibleAds = null;
let refreshIntervalID = 0;
let adObserver;

class Tracking {

    constructor() {

        const body = document.querySelector('body');

        const pageData = {
            url: window.location.href,
            pageTitle: body.getAttribute('data-page-title'),
        }

        this.trackPageView(pageData);

        let manufacturerLinks = document.querySelectorAll('.manufacturer-link');
        let offerContainers = document.querySelectorAll('.offer-container');
        let personalizedOfferContainers = document.querySelectorAll('.offer-container-personalized');

        if(manufacturerLinks) {
            manufacturerLinks.forEach((e) => {
                e.addEventListener('click', () => {
                    const linkData = {
                        offerName: e.getAttribute('data-offer-name'),
                        offerManufacturerBrand: e.getAttribute('data-manufacturer-brand'),
                        offerManufacturerType: e.getAttribute('data-manufacturer-type'),
                        offerAdId: e.getAttribute('data-ad-id'),
                        offerId: e.getAttribute('data-ad-id'),
                        offerCategory: e.getAttribute('data-offer-category'),
                        offerType: e.getAttribute('data-offer-type')
                    };

                    this.trackManufacturerLink(linkData);
                });
            });
        }

        if(personalizedOfferContainers || offerContainers) {
            document.addEventListener("visibilitychange", this.handleVisibilityChange, false);

            const observerOptions = {
                root: null,
                rootMargin: "0px",
                threshold: [0.0, 0.75],
              };

              adObserver = new IntersectionObserver(this.intersectionCallback, observerOptions);

              refreshIntervalID = setInterval(this.handleRefreshInterval, 1000);

              if(offerContainers) {
                offerContainers.forEach(offer => {
                    adObserver.observe(offer);
                })
              }

              if(personalizedOfferContainers) {
                personalizedOfferContainers.forEach(offer => {
                    adObserver.observe(offer);
                })
              }
        }
    }

    handleRefreshInterval() {
        visibleAds.forEach((offer) => {
          const previousTime = offer.dataset.totalViewTime;
          updateAdTimer(offer, false);

          if (
            offer.dataset.entered === "false" &&
            offer.dataset.totalViewTime >= 1000
          ) {
            offer.dataset.entered = true;

            const linkData = {
                offerName: offer.dataset.offerName,
                offerManufacturerBrand: offer.dataset.manufacturerBrand,
                offerManufacturerType: offer.dataset.manufacturerType,
                offerAdId: offer.dataset.adId,
                offerId: offer.dataset.offerId,
                offerCategory: offer.dataset.offerCategory,
                offerType: offer.dataset.offerType,
                personalized: offer.dataset.personalized,
                pPlus: offer.dataset.pPlus
            };
            trackOfferScroll(linkData, offer);
          }

        });
    }

    async trackManufacturerLink(linkData) {

        const formData = new FormData();

        formData.append('offer_name', linkData.offerName);
        formData.append('offer_manufacturer_brand', linkData.offerManufacturerBrand);
        formData.append('offer_manufacturer_type', linkData.offerManufacturerType);
        formData.append('offer_ad_id', linkData.offerAdId);
        formData.append('offer_offer_category', linkData.offerCategory);
        formData.append('offer_type', linkData.offerType);

        const response = await fetch("/tracking/track-offer-click", {
            method: "POST",
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
    }

    async trackPageView(pageData) {

        TimeMe.initialize({
            currentPageName: pageData.pageTitle, // current page
            idleTimeoutInSeconds: 30 // seconds
        });

        const formData = new FormData();
        formData.append('url', pageData.url);
        formData.append('pagetitle', pageData.pageTitle);

        const response = await fetch("/tracking/track-page-view", {
            method: "POST",
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
    }

    handleVisibilityChange() {
        let offerContainers = document.querySelectorAll('.offer-container');
        let personalizedOfferContainers = document.querySelectorAll('.offer-container-personalized');

        if (document.hidden) {
            if (!previouslyVisibleAds) {
                previouslyVisibleAds = visibleAds;
                visibleAds.clear();
                if(offerContainers) {
                    offerContainers.forEach((offer) => {
                        updateAdTimer(offer, true);
                    });
                }
                if(personalizedOfferContainers) {
                    personalizedOfferContainers.forEach((offer) => {
                        updateAdTimer(offer, true);
                    });
                }
            }
        } else {
            if(offerContainers) {
                offerContainers.forEach(offer => {
                    adObserver.observe(offer);
                })
              }

              if(personalizedOfferContainers) {
                personalizedOfferContainers.forEach(offer => {
                    adObserver.observe(offer);
                })
              }
              previouslyVisibleAds = null;

        }
    }

    intersectionCallback(entries) {
        entries.forEach((entry) => {
          const offer = entry.target;

          if (entry.isIntersecting) {
            if (entry.intersectionRatio >= 0.75) {
              offer.dataset.lastViewStarted = entry.time;
              visibleAds.add(offer);
            }
          } else {
            visibleAds.delete(offer);
          }
        });
    }
}

function updateAdTimer(offer, end) {
    const lastStarted = offer.dataset.lastViewStarted;
    const currentTime = performance.now();

    if (lastStarted) {
      const diff = currentTime - lastStarted;

      offer.dataset.totalViewTime =
        parseFloat(offer.dataset.totalViewTime) + diff;
    }

    offer.dataset.lastViewStarted = currentTime;

    if(end === true) {
        const insertId = offer.dataset.enteredId;

        if(insertId && offer.dataset.totalViewTime > 0) {
            const formData = new FormData();

            formData.append('insert_id', insertId);
            formData.append('offer_time_viewed', offer.dataset.totalViewTime);

            fetch("/tracking/track-offer-scroll", {
                method: "POST",
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
        }

        offer.dataset.totalViewTime = 0;
        offer.dataset.entered = "false";
        offer.dataset.enteredId = "";
        offer.dataset.lastViewStarted = "0:00";
        adObserver.unobserve(offer);
    }
}

async function trackOfferScroll(linkData, offer) {
    const formData = new FormData();

    formData.append('offer_name', linkData.offerName);
    formData.append('offer_ad_id', linkData.offerAdId);
    formData.append('offer_id', linkData.offerId);
    formData.append('offer_offer_category', linkData.offerCategory);
    formData.append('offer_type', linkData.offerType);
    formData.append('personalized', linkData.personalized);
    formData.append('p_plus', linkData.pPlus);

    const response = await fetch("/tracking/track-offer-scroll", {
        method: "POST",
        body: formData,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).then((response) => {
        return response.json();
    });

    offer.dataset.enteredId = await response;
}

export default Tracking;