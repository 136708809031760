class Register {

    constructor() {
        const phoneNumber = document.getElementById("cellphone");
        const showPassword = document.getElementById("showPassword");
        const dobMonth = document.getElementById("dob-month");

        if(dobMonth) {
            dobMonth.addEventListener('change', (e) => {
                e.preventDefault();
                const dobDate = document.getElementById("dob-day");
                let optLength = dobDate.options.length;

                for (optLength = optLength-1; optLength >= 0; optLength--) {
                    dobDate.options[optLength] = null;
                }

                let value = dobMonth.value;
                const thirtyOneMonths = ['01', '03', '05', '07', '08', '10', '12'];
                const thirtyMonths = ['04', '06', '09', '11'];
                const twentyNineMonths = ['02'];
                const thirtyOne = {'1':'01','2':'02','3':'03','4':'04','5':'05','6':'06','7':'07','8':'08','9':'09','10':'10','11':'11','12':'12','13':'13','14':'14','15':'15','16':'16','17':'17','18':'18','19':'19','20':'20','21':'21','22':'22','23':'23','24':'24','25':'25','26':'26','27':'27','28':'28','29':'29','30':'30','31':'31'};
                const thirty = {'1':'01','2':'02','3':'03','4':'04','5':'05','6':'06','7':'07','8':'08','9':'09','10':'10','11':'11','12':'12','13':'13','14':'14','15':'15','16':'16','17':'17','18':'18','19':'19','20':'20','21':'21','22':'22','23':'23','24':'24','25':'25','26':'26','27':'27','28':'28','29':'29','30':'30'};
                const twentyNine = {'1':'01','2':'02','3':'03','4':'04','5':'05','6':'06','7':'07','8':'08','9':'09','10':'10','11':'11','12':'12','13':'13','14':'14','15':'15','16':'16','17':'17','18':'18','19':'19','20':'20','21':'21','22':'22','23':'23','24':'24','25':'25','26':'26','27':'27','28':'28','29':'29'};

                let option = document.createElement("option");
                option.text = "Select Day";
                option.value = "";
                dobDate.appendChild(option);

                if(thirtyOneMonths.includes(value)) {
                    for (let key in thirtyOne) {
                        let option = document.createElement("option");
                        option.setAttribute('value', thirtyOne[key]);

                        let optionText = document.createTextNode(key);
                        option.appendChild(optionText);

                        dobDate.appendChild(option);
                    }
                }
                if(thirtyMonths.includes(value)) {
                    for (let key in thirty) {
                        let option = document.createElement("option");
                        option.setAttribute('value', thirty[key]);

                        let optionText = document.createTextNode(key);
                        option.appendChild(optionText);

                        dobDate.appendChild(option);
                    }
                }
                if(twentyNineMonths.includes(value)) {
                    for (let key in twentyNine) {
                        let option = document.createElement("option");
                        option.setAttribute('value', twentyNine[key]);

                        let optionText = document.createTextNode(key);
                        option.appendChild(optionText);

                        dobDate.appendChild(option);
                    }
                }
            });
        }

        if(phoneNumber) {
            phoneNumber.addEventListener('blur', function(e) {
                let value = phoneNumber.value;
                if(value) {
                    const pN = value.replace(/[^\d]/g, "");
                    const phoneNumberLength = value.length;
                    if (phoneNumberLength < 4) {
                        value = pN;
                    } else if (phoneNumberLength < 7) {
                        value = `${pN.slice(0, 3)}-${pN.slice(3)}`;
                    }

                    value = `${pN.slice(0, 3)}-${pN.slice(
                    3,
                    6
                    )}-${pN.slice(6, 10)}`;

                    phoneNumber.value = value;
                }
            });
        }

        if(showPassword) {
            showPassword.addEventListener('click', (e) => {
                e.preventDefault();

                const passwordInput = document.getElementById('password');
                const passwordRepeatInput = document.getElementById('repeat-password');

                if (passwordInput.type === "password") {
                    passwordInput.type = "text";
                } else {
                    passwordInput.type = "password";
                }

                if (passwordRepeatInput.type === "password") {
                    passwordRepeatInput.type = "text";
                } else {
                    passwordRepeatInput.type = "password";
                }

                showPassword.classList.toggle('hidden');
            })
        }

    }
}
export default Register;