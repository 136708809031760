import { Loader } from "@googlemaps/js-api-loader"

class Locations {

    constructor() {

        const map = document.getElementById('map');

        if(map) {
            const filterButton = document.getElementById('filterButton');
            const clearFiltersButton = document.getElementById('clearFilters');

            filterButton.addEventListener('click', () => {
                const form = document.getElementById('mapFiltersForm');

                if(form.classList.contains('hide')) {
                    form.classList.remove('hide');
                    clearFiltersButton.classList.remove('hide');
                    filterButton.innerHTML = 'Filter By: <span>(click to hide)</span>';
                } else {
                    form.classList.add('hide');
                    clearFiltersButton.classList.add('hide');
                    filterButton.innerHTML = 'Show Filters +';
                }
            });

            const loader = new Loader({
                apiKey: "AIzaSyDF-epgaCr2QWPTtLXtF76OVyljeqmqtI8",
                version: "weekly"
            });

            loader.load().then(async () => {
                const response = await fetch("/locations/get-store-locations").then((response) => {
                    return response.json();
                  }).catch(function(err) {
                  });

                await this.loadMap(response);
            });

            const clearFilters = document.getElementById('clearFilters');
            const mapFilterSubmit = document.getElementById('mapFilterSubmit');

            clearFilters.addEventListener('click', async (e) => {
                e.preventDefault();
                const zipCode = document.getElementById('zip-code');
                let checkboxes = document.querySelectorAll("input[type = 'checkbox']");

                checkboxes.forEach(function(checkbox) {
                    checkbox.checked = false;
                  });
                zipCode.value = "";

                const filterButton = document.getElementById('filterButton');
                filterButton.click();

                const response = await fetch("/locations/get-store-locations").then((response) => {
                    return response.json();
                  }).catch(function(err) {
                  });

                await this.loadMap(response);

            });

            mapFilterSubmit.addEventListener('click', (e) => {

                filterButton.click();

                e.preventDefault();

                this.getFormData().then(async (data) => {
                    if(data) {
                        const response = await fetch("/locations/filter-store-locations", {
                            method: "POST",
                            body: data,
                            credentials : 'same-origin'
                        }).then((response) => {
                            return response.json();
                          }).catch(function(err) {
                          });

                        await this.loadMap(response);
                    }
                });
            });

        }
    }

    async getFormData() {
        const form = document.getElementById('mapFiltersForm');
        const formData = new FormData(form);

        return formData;
    }

    async loadMap(data) {
        // Request needed libraries.
        const { Map, InfoWindow } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
            "marker"
        );
        const map = new Map(document.getElementById("map"), {
            zoom: 8,
            center: { lat: data.defaultLocation.lat, lng: data.defaultLocation.lng },
            mapId: "4504f8b37365c3d0",
            disableDefaultUI: true,
        });

        // Create an info window to share between markers.
        const infoWindow = new InfoWindow();

        // Create the markers.
        data.locations.forEach((location) => {
            const marker = new AdvancedMarkerElement({
            position: {lat: location.lat, lng: location.lng},
            map,
            title: location.name,
            });

            // Add a click listener for each marker, and set up the info window.
            marker.addListener("click", () => {
                let contentString = '<div class="map-marker">' +
                '<h2 class="map-title">' + location.name + '</h2>';

                if(location.address_prefix) {
                    contentString += '<h3 class="map-sub-title">' + location.address_prefix + '</h3>';
                }

                contentString += '<p>' + location.address + '</p>';

                if(location.address2) {
                    contentString += '<p>' + location.address2 + '</p>';
                }
                contentString += '<p>' + location.city + ', ' + location.state + ' ' + location.zip_code + '</p>';

                contentString += '<a class="button map-button" href="/locations/view/' + location.id + '"> Store Hours & Info </a>';

                infoWindow.close();
                infoWindow.setContent(contentString);
                infoWindow.open(marker.map, marker);

                map.setCenter(new google.maps.LatLng( location.lat, location.lng ) );
            });
        });
    }

}
export default Locations;