class Logs {

    constructor() {
        const logFilterSubmit = document.getElementById('logFilters');

        if(logFilterSubmit) {
            this.addLogFilterSubmitListener();
        }
    }

    addLogFilterSubmitListener() {
        const logFilterSubmit = document.getElementById('logFilters');

        logFilterSubmit.addEventListener('click', (e) => {
            e.preventDefault();

            const queryString = window.location.search;
            const callType = document.getElementById('calltypefilter').value;
            const storeNumber = document.getElementById('storenumberfilter').value;
            const date = document.getElementById('dateFilter').value;

            window.location = '/admin/altria-api-logs/index/' + callType + '/' + storeNumber + '/' + date + queryString;

        });
    }
}
export default Logs;