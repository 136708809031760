import PullToRefresh from 'pulltorefreshjs';
import Swiper from 'swiper/bundle';

import 'swiper/css';

class Globals {

    constructor() {
        this.addMenuListener();
        this.addAppMenuListener();
        this.addWindowListener();

        const messageCount = document.getElementById('messageCount');
        const offerCategorySelect = document.getElementById('offerCategorySelect');
        const swiper = document.querySelector('.swiper');
        const body = document.querySelector('body');
        let avCheck = body.dataset.avCheck;
        let loggedIn = body.dataset.avLoggedIn

        if(avCheck && avCheck == 1 && loggedIn == 1) {
            this.checkAvStatus();
            setTimeout(this.checkAvStatus, 300000);
        }

        PullToRefresh.init({
            mainElement: 'body',
            shouldPullToRefresh: function(){
                const body = document.querySelector('body');
                if(body.classList.contains("authentication") || body.classList.contains("locations")) {
                    return false;
                } else {
                    return !window.scrollY;
                }
            },
            onRefresh() {
              window.location.reload();
            }
          });

          if(swiper) {
            new Swiper('.swiper', {
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: "auto",
                coverflowEffect: {
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }
            });
          }

        if(offerCategorySelect) {
            offerCategorySelect.addEventListener('change', (e) => {
                window.location = '/offers/restricted/' + offerCategorySelect.value;
            });
        }

        if(messageCount) {
            this.checkMessages();
            setInterval(this.checkMessages, 300000);
        }

        let urlHash = new URLSearchParams(document.location.search);
        urlHash = urlHash.get("st");

        if(urlHash) {
            urlHash = document.getElementById(urlHash);
            if(urlHash) {
                urlHash.scrollIntoView({behavior: 'smooth'});
            }
        }

    }

    addMenuListener() {
        const menuToggle = document.getElementById('toggleMenu');

        if(menuToggle) {
            menuToggle.addEventListener('click', (e) => {
                this.toggleMenu();
            });
        }
    }

    addAppMenuListener() {
        const mainMenuToggle = document.getElementById('mainMenuToggle');
        const mainMenuClose = document.getElementById('mainMenuClose');
        const appView = document.getElementById('appView');
        const bodyElem = document.querySelector('body');

        if(appView) {
            appView.addEventListener('click', (e) => {
                if(bodyElem.classList.contains("closeable")) {
                    bodyElem.classList.remove('menu-open');
                    bodyElem.classList.remove('closeable');
                }
            });
        }

        if(mainMenuToggle) {
            mainMenuToggle.addEventListener('click', (e) => {
                this.toggleAppMenu();
            });
        }
        if(mainMenuClose) {
            mainMenuClose.addEventListener('click', (e) => {
                bodyElem.classList.remove('menu-open');
                bodyElem.classList.remove('closeable');
            });
        }
    }

    addWindowListener() {
        const body = document.querySelector('body');

        window.addEventListener("load", () => {
            let isMobile = navigator.userAgent.toLowerCase().match(/mobile/i);

            if(isMobile) {
                let isInstalled = this.checkInstalled();

                if(isInstalled == false) {
                    const session = body.dataset.installSession;
                    const cookie = this.getCookie('installSession');
                    const url = new URL(window.location.href);

                    if(url.pathname == '/users/forgot-password') {
                        return;
                    }

                    if(!session || !cookie && url.pathname != '/home/install') {

                        window.location = '/home/install';

                    } else if (!session || !cookie) {
                        this.setCookie('installSession', true, {sameSite:'strict', secure: true, 'max-age': (10 * 365 * 24 * 60 * 60)})
                    }
                }
            }
        });

        window.addEventListener('resize', () => {
            if(window.innerWidth > 900) {
                if(body.classList.contains('menu-active')) {
                    this.toggleMenu();
                }
            }
        }, true);
    }

    toggleMenu() {
        const body = document.querySelector('body');

        body.classList.toggle('menu-active');
    }
    toggleAppMenu() {
        const body = document.querySelector('body');

        if(body.classList.contains("menu-open")) {
            body.classList.remove('menu-open');
            body.classList.remove('closeable');
        } else {
            body.classList.add('menu-open');
            setTimeout(() => {
                body.classList.add('closeable');
            }, 1000);
        }
    }

    async checkMessages() {
        const response = await fetch("/messages/fetch-message-count").then(function(response) {
            return response.json();
          });
          const messageCount = document.getElementById('messageCount');
          messageCount.innerHTML = "<span>" + response + "</span>";

        if(response > 0) {
            messageCount.style.display = "flex";
            if (navigator.setAppBadge) {
                // Display the number of unread messages.
                navigator.setAppBadge(response);
            }
        } else {
            messageCount.style.display = "none";
            if (navigator.clearAppBadge) {
                // Display the number of unread messages.
                navigator.clearAppBadge();
            }
        }
    }

    checkInstalled() {
        // For iOS
        if(window.navigator.standalone) return true

        // For Android
        if(window.matchMedia('(display-mode: standalone)').matches) return true

        // If neither is true, it's not installed
        return false
    }

    setCookie(name, value, options = {}) {
        options = {
          path: '/',
          ...options
        };

        if (options.expires instanceof Date) {
          options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
          updatedCookie += "; " + optionKey;
          let optionValue = options[optionKey];
          if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
          }
        }

        document.cookie = updatedCookie;
      }

      getCookie(name) {
        let matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
      }

      async checkAvStatus() {

          const response = await fetch("/users/check-av-status").then(function(response) {
            return response.text();
          });

        if(response == 1) {
            window.location.replace('/home/');
        }
    }
}

export default Globals;