class Offers {

    constructor() {
        const offerType = document.getElementById('offer-type');
        const offerFilterSubmit = document.getElementById('offerFilters');
        const replaceHomepageImage = document.getElementById('replace-homepage-image');
        const replaceLoyaltyImage = document.getElementById('replace-loyalty-image');
        const relatedOffersContainer = document.getElementById('relatedOffersContainer');
        const manufacturerSelect = document.getElementById('manufacturer-type');
        const loyaltyManufacturerSelect = document.getElementById('loyalty-manufacturer-type');
        const offerCategorySelect = document.getElementById('offer-category-id');
        const manufacturerBrandContainer = document.getElementById('manufacturerBrandContainer');
        const loyaltyOfferImageContainer = document.getElementById('loyaltyBrandContainer');
        const subject = document.getElementById('subject');
        const content = document.getElementById('content');
        const pushTitle = document.getElementById('push-title');
        const pushMessage = document.getElementById('push-message');
        let pushTitleChanged = false;
        let pushMessageChanged = false;

        if(manufacturerBrandContainer) {
            this.updateBrands();
        }
        if(loyaltyOfferImageContainer) {
            this.updateLoyaltyBrands();
        }

        if(subject && pushTitle) {
            pushTitle.addEventListener('blur', (e) => {
                if(pushTitle.value == '') {
                    pushTitleChanged = false;
                } else {
                    pushTitleChanged = true;
                }
            });

            subject.addEventListener('blur', (e) => {
                if(pushTitleChanged === false) {
                    pushTitle.value = subject.value;
                }
            });
        }

        if(content && pushMessage) {
            pushMessage.addEventListener('blur', (e) => {
                if(pushMessage.value == '') {
                    pushMessageChanged = false;
                } else {
                    pushMessageChanged = true;
                }
            });

            content.addEventListener('blur', (e) => {
                if(pushMessageChanged === false) {
                    pushMessage.value = content.value;
                }
            });
        }

        if(offerType) {
            this.checkOfferType();
            this.addOfferTypeListener();
        }

        if(offerFilterSubmit) {
            this.addOfferFilterSubmitListener();
        }

        if(offerCategorySelect && relatedOffersContainer) {
            this.addOfferCategorySelectListener();
        }

        if(manufacturerSelect && relatedOffersContainer) {
            this.addManufacturerSelectListener();
        }


        if(loyaltyManufacturerSelect && relatedOffersContainer) {
            this.addLoyaltyManufacturerSelectListener();
        }

        if(replaceHomepageImage) {
            this.checkReplacementHomepageImage();
            this.addCheckReplaceHomepageImageListener();
        }

        if(replaceLoyaltyImage) {
            this.checkReplacementLoyaltyImage();
            this.addCheckReplaceLoyaltyImageListener();
        }
    }

    checkOfferType() {
        const offerType = document.getElementById('offer-type');
        const offerCategories = document.getElementById('offerCategories');
        const manufacturerTypes = document.getElementById('manufacturerTypes');
        const loyaltyOfferImage = document.getElementById('loyaltyOfferImage');
        const homepageOfferImage = document.getElementById('homepageOfferImage');
        const detailsContainer = document.getElementById('detailsContainer');
        const termsContainer = document.getElementById('termsContainer');
        const linkToOffersContainer = document.getElementById('linkToOffersContainer');
        const manufacturerLinkContainer = document.getElementById('manufacturerLinkContainer');
        const manufacturerBrandContainer = document.getElementById('manufacturerBrandContainer');
        const loyaltyBrandContainer = document.getElementById('loyaltyBrandContainer');
        const adIdContainer = document.getElementById('adIdContainer');
        const offerCategory = document.getElementById('offer-category-id');
        const loyaltyManufacturerType = document.getElementById('loyaltyManufacturerType');

        let showHomepage = 'block';
        let showLoyalty = 'block';
        let showCategories = 'block';
        let showManufacturerTypes = 'block';
        let showDetails = 'block';
        let showTerms = 'block';
        let showlinkToOffers = 'block';
        let showManufacturerLink = 'block';
        let showManufacturerBrandContainer = 'block';
        let showLoyaltyBrandContainer = 'block';
        let showAdIdContainer = 'block';
        let showLoyaltyManufacturerType = 'block';

        if(offerType.options[offerType.selectedIndex].value === 'homepage' || offerType.options[offerType.selectedIndex].value === 'products') {

            showHomepage = 'block';
            showLoyalty = 'none';
            showlinkToOffers = 'block';
            showCategories = 'none'
            showManufacturerTypes = 'none';
            showTerms = 'none';
            showDetails = 'none';
            showManufacturerLink = 'none';
            showManufacturerBrandContainer = 'none';
            showLoyaltyBrandContainer = 'none';
            showAdIdContainer = 'none';

            this.checkReplacementHomepageImage();

        } else if (offerType.options[offerType.selectedIndex].value === 'manufacturer') {

            showHomepage = 'none';
            showLoyalty = 'block';
            showlinkToOffers = 'none';
            showTerms = 'none';
            showDetails = 'none';
            showManufacturerLink = 'block';
            showManufacturerTypes = 'block';
            showManufacturerBrandContainer = 'block';
            showLoyaltyBrandContainer = 'none';
            showAdIdContainer = 'block';
            showLoyaltyManufacturerType = 'none';

        } else {
            showHomepage = 'none';
            showLoyalty = 'block';
            showlinkToOffers = 'none';
            showManufacturerLink = 'none';
            showManufacturerTypes = 'none';
            showManufacturerBrandContainer = 'none';
            showLoyaltyBrandContainer = 'block';
            showAdIdContainer = 'block';

            this.checkReplacementLoyaltyImage();
        }

        if(offerType.options[offerType.selectedIndex].value === 'products') {
            showlinkToOffers = 'none';
        }

        if(offerCategory.options[offerCategory.selectedIndex].text === 'Non Tobacco') {
            showLoyaltyBrandContainer = 'none';
            showLoyaltyManufacturerType = 'none';
        }


        homepageOfferImage.style.display = showHomepage;
        loyaltyOfferImage.style.display = showLoyalty;
        offerCategories.style.display = showCategories;
        detailsContainer.style.display = showDetails;
        termsContainer.style.display = showTerms;
        linkToOffersContainer.style.display = showlinkToOffers;
        manufacturerLinkContainer.style.display = showManufacturerLink;
        manufacturerTypes.style.display = showManufacturerTypes;
        manufacturerBrandContainer.style.display = showManufacturerBrandContainer;
        loyaltyBrandContainer.style.display = showLoyaltyBrandContainer;
        adIdContainer.style.display = showAdIdContainer;
        loyaltyManufacturerType.style.display = showLoyaltyManufacturerType;

        if(relatedOffersContainer) {
            this.updateRelatedRecords();
        }

    }

    addOfferTypeListener() {
        const offerType = document.getElementById('offer-type');

        offerType.addEventListener('change', () => {
            this.checkOfferType();
        });
    }

    addOfferCategorySelectListener() {
        const offerCategorySelect = document.getElementById('offer-category-id');

        offerCategorySelect.addEventListener('change', () => {
            this.updateRelatedRecords();
            this.checkOfferType();
        });
    }

    addManufacturerSelectListener() {
        const manufacturerSelect = document.getElementById('manufacturer-type');

        manufacturerSelect.addEventListener('change', () => {
            this.updateRelatedRecords();
            this.updateBrands();
        });
    }

    addLoyaltyManufacturerSelectListener() {
        const loyaltyManufacturerSelect = document.getElementById('loyalty-manufacturer-type');

        loyaltyManufacturerSelect.addEventListener('change', () => {
            this.updateRelatedRecords();
            this.updateLoyaltyBrands();
        });
    }

    addOfferFilterSubmitListener() {
        const offerFilterSubmit = document.getElementById('offerFilters');

        offerFilterSubmit.addEventListener('click', (e) => {
            e.preventDefault();

            const queryString = window.location.search;
            const category = document.getElementById('offercategory').value;
            const filter = document.getElementById('offerfilter').value;
            const manufacturer = document.getElementById('manufacturersfilter').value;
            const restricted = document.getElementById('restrictedfilter').value;
            const offerType = document.getElementById('offertypefilter').value;

            window.location = '/admin/offers/index/' + category + '/' + filter + '/' + manufacturer + '/' + offerType + '/' + restricted + queryString;

        });
    }

    addCheckReplaceHomepageImageListener() {
        const replaceHomepageImage = document.getElementById('replace-homepage-image');

        if(replaceHomepageImage) {
            replaceHomepageImage.addEventListener('click', (e) => {
                this.checkReplacementHomepageImage();
            });

        }
    }

    checkReplacementHomepageImage() {
        const replaceHomepageImage = document.getElementById('replace-homepage-image');
        const homepageOfferImageContainer = document.getElementById('homepageOfferImageContainer');

        if(replaceHomepageImage) {
            if(replaceHomepageImage.checked == true) {
                homepageOfferImageContainer.style.display = 'block';
            } else {
                homepageOfferImageContainer.style.display = 'none';
            }
        }
    }

    addCheckReplaceLoyaltyImageListener() {
        const replaceLoyaltyImage = document.getElementById('replace-loyalty-image');

        if(replaceLoyaltyImage) {
            replaceLoyaltyImage.addEventListener('click', (e) => {
                this.checkReplacementLoyaltyImage();
            });
        }
    }

    checkReplacementLoyaltyImage() {
        const replaceLoyaltyImage = document.getElementById('replace-loyalty-image');
        const loyaltyOfferImageContainer = document.getElementById('loyaltyOfferImageContainer');

        if(replaceLoyaltyImage) {
            if(replaceLoyaltyImage.checked == true) {
                loyaltyOfferImageContainer.style.display = 'block';
            } else {
                loyaltyOfferImageContainer.style.display = 'none';
            }
        }
    }


    updateRelatedRecords() {
        const relatedOffersContainer = document.getElementById('relatedOffersContainer');

        const editId = relatedOffersContainer.getAttribute('data-edit-id');

        let offerTypeId = document.getElementById('offer-type');
        offerTypeId = offerTypeId.options[offerTypeId.selectedIndex].value ?? false;

        let offerCategoryId = document.getElementById('offer-category-id');
        offerCategoryId = offerCategoryId.options[offerCategoryId.selectedIndex].value ?? false;

        let manufacturerType = document.getElementById('manufacturer-type');
        manufacturerType = manufacturerType.options[manufacturerType.selectedIndex].value ?? false;

        let url = '/admin/offers/update-related-offers/' + offerTypeId + '/' + offerCategoryId + '/' + manufacturerType;
        if(editId) {
            url = url + "/" + editId;
        }

        fetch(url)
        .then((response) => {
            return response.text();
          })
          .then((html) => {
            relatedOffersContainer.innerHTML = html;
          })
        .catch(err => {
            relatedOffersContainer.innerHTML = "<h4>Couldn't fetch related offers. Please reload the page.</h4>";
        })
    }

    updateLoyaltyBrands() {
        const loyaltyBrandContainer = document.getElementById('loyaltyBrandContainer');
        const loyaltyManufacturerSelect = document.getElementById('loyalty-manufacturer-type');
        const editBrand = loyaltyBrandContainer.getAttribute('data-edit-select');
        const updated = loyaltyBrandContainer.getAttribute('data-edit-select-updated');
        let manufacturer = loyaltyManufacturerSelect.options[loyaltyManufacturerSelect.selectedIndex].value ?? false;

        let url = '/admin/offers/update-loyalty-manufacturer-brands/' + manufacturer;

        fetch(url)
        .then((response) => {
            return response.text();
          })
          .then((html) => {
            loyaltyBrandContainer.innerHTML = html;
          }).then(() => {
            const loyaltyManufacturerSelect = document.getElementById('loyalty-manufacturer-brand');

            if(updated == "update" && editBrand) {
                loyaltyManufacturerSelect.value = editBrand;
                loyaltyBrandContainer.setAttribute('data-edit-select-updated', "no-update");
            }
          })
        .catch(err => {
            loyaltyBrandContainer.innerHTML = "<h4>Couldn't fetch related offers. Please reload the page.</h4>";
        })
    }

    updateBrands() {
        const manufacturerBrandContainer = document.getElementById('manufacturerBrandContainer');
        const manufacturerSelect = document.getElementById('manufacturer-type');
        const editBrand = manufacturerBrandContainer.getAttribute('data-edit-select');
        const updated = manufacturerBrandContainer.getAttribute('data-edit-select-updated');
        let manufacturer = manufacturerSelect.options[manufacturerSelect.selectedIndex].value ?? false;

        let url = '/admin/offers/update-manufacturer-brands/' + manufacturer;

        fetch(url)
        .then((response) => {
            return response.text();
          })
          .then((html) => {
            manufacturerBrandContainer.innerHTML = html;
          }).then(() => {
            const manufacturerBrandSelect = document.getElementById('manufacturer-brand');

            if(updated == "update" && editBrand) {
                manufacturerBrandSelect.value = editBrand;
                manufacturerBrandContainer.setAttribute('data-edit-select-updated', "no-update");
            }
          })
        .catch(err => {
            manufacturerBrandContainer.innerHTML = "<h4>Couldn't fetch related offers. Please reload the page.</h4>";
        })
    }
}
export default Offers;