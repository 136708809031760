class MessageThreads {

    constructor() {
        const messageType = document.getElementById('message-type-id');
        const messageFilterSubmit = document.getElementById('messageFilters');
        const dateRangeFilter = document.getElementById('daterangefilter');

        if(messageType) {
            messageType.addEventListener('change', () => {
                this.updatePlaceholders();
            });
        }

        if(messageFilterSubmit) {
            this.addMessageFilterSubmitListener();
        }

        if(dateRangeFilter) {
            this.checkFiltersCheckbox();

            dateRangeFilter.addEventListener('change', () => {
                this.checkFiltersCheckbox();
            })
        }
    }

    checkFiltersCheckbox() {
        const dateRangeFilter = document.getElementById('daterangefilter');
        const showDates = document.getElementById('showDates');
        if(dateRangeFilter.checked == true) {
            showDates.style.display = 'flex';
        } else {
            showDates.style.display = 'none';
        }
    }

    updatePlaceholders() {
        const messageForm = document.getElementById('userMessageForm');
        const pushTitleOffer = messageForm.getAttribute('data-push-title-offer');
        const pushMessageOffer = messageForm.getAttribute('data-push-message-offer');
        const pushTitleMessage = messageForm.getAttribute('data-push-title-message');
        const pushMessageMessage = messageForm.getAttribute('data-push-message-message');
        const messageType = document.getElementById('message-type-id');
        const pushTitle = document.getElementById('push-title');
        const pushMessage = document.getElementById('push-message');

        if(messageType.options[messageType.selectedIndex].value === "1") {

            pushTitle.placeholder = pushTitleMessage;
            pushMessage.placeholder = pushMessageMessage;

        } else if (messageType.options[messageType.selectedIndex].value === "2") {

            pushTitle.placeholder = pushTitleOffer;
            pushMessage.placeholder = pushMessageOffer;

        }

    }

    addMessageFilterSubmitListener() {
        const messageFilterSubmit = document.getElementById('messageFilters');

        messageFilterSubmit.addEventListener('click', (e) => {
            e.preventDefault();

            const messageType = document.getElementById('messagetypefilter').value;
            const sender = document.getElementById('senderfilter').value;
            const adminStatus = document.getElementById('adminstatusfilter').value;
            const userStatus = document.getElementById('userstatusfilter').value;
            const dateStart = document.getElementById('start-date').value || null;
            const dateEnd = document.getElementById('end-date').value || null;
            const dateRangeFilter = document.getElementById('daterangefilter');
            const lastMessageSentBy = document.getElementById('lastmessagesentbyfilter').value;
            let showDates = false;

            if(dateRangeFilter.checked == true) {
                showDates = true;
            }
            window.location = '/admin/message-threads/index/' + messageType + '/' + sender + '/' + adminStatus + '/' + userStatus + '/' + dateStart + '/' + dateEnd + '/' + showDates + '/' + lastMessageSentBy;

        });
    }

}
export default MessageThreads;