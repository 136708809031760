import toUint8Array from 'urlb64touint8array';

let checkServiceWorker = true;
let checkPushManager = true;

class Push {

    constructor() {
        if (!('serviceWorker' in navigator)) {
            // Service Worker isn't supported on this browser, disable or hide UI.
            checkServiceWorker = false;
        }

        if (!('PushManager' in window)) {
            // Push isn't supported on this browser, disable or hide UI.
            checkPushManager = false;
            const hideOptIn = document.getElementById('pushNotificationsOptin');
            if(hideOptIn) {
                hideOptIn.setAttribute('style', "display:none !important;");
            }
        }

        if(checkServiceWorker && checkPushManager) {
            let loggedIn = document.querySelector('body');
            loggedIn = loggedIn.dataset.loggedIn;

            if(loggedIn == true) {
                checkSubscription();
            }

            const pushNotificationsOptin = document.getElementById('pushNotificationsOptin');
            const consentWebPushToggle = document.querySelector('.preferences #consent-web-push');

            if(consentWebPushToggle) {
                consentWebPushToggle.addEventListener('click', (e) => {
                    if(consentWebPushToggle.checked === true) {
                        const result = askPermission();
                    } else {
                        unsubscribePush();
                    }
                });
            }

            if(pushNotificationsOptin) {
                const pushOptIn = document.getElementById('pushOptIn');
                const pushOptOut = document.getElementById('pushOptOut');

                pushOptIn.addEventListener('click', (e) => {
                    pushNotificationsOptin.classList.remove('active');
                    pushNotificationsOptin.classList.add('inactive');
                    pushOptIn.disabled = true;
                    pushOptOut.disabled = true;
                    askPermission();
                });

                pushOptOut.addEventListener('click', (e) => {
                    pushNotificationsOptin.classList.remove('active');
                    pushNotificationsOptin.classList.add('inactive');
                    pushOptIn.disabled = true;
                    pushOptOut.disabled = true;
                    unsubscribePush();
                });
            }
        }
    }
}

async function askPermission() {
    const result = await Notification.requestPermission();
    const registration = await navigator.serviceWorker.getRegistration();
    const subscribed = await registration.pushManager.getSubscription();

    if (result === 'denied') {
        unsubscribePush();
        return;
    }

    if (subscribed) {
        let formData = new FormData();

        formData.append('subscription', JSON.stringify(subscribed));

        const cpsResponse = await fetch("/users/check-push-subscription", {
            method: "POST",
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        await cpsResponse.text();
        return cpsResponse.ok;
  }

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array("BG7qni-d8-moij29PxYD1GF1DuVTK1rZK_fh0EnDhMNFh2zk_KvUnLy5_gJVNulvvThV_MB9STiu_nNqZCQM-P0")
    });

      if(subscription) {
        let formData = new FormData();

        formData.append('subscription', JSON.stringify(subscription));

        const apeResponse = await fetch('/users/add-push-endpoint', {
            method: 'POST',
            headers: {
              'X-Requested-With': 'XMLHttpRequest'
            },
            body: formData
          });

          await apeResponse.text();
          return apeResponse.ok;
      }
}

async function checkSubscription() {
    const registration = await navigator.serviceWorker.getRegistration();
    const subscription = await registration.pushManager.getSubscription();

    if (subscription) {
        let formData = new FormData();

        formData.append('subscription', JSON.stringify(subscription));

        const cpsResponse = await fetch("/users/check-push-subscription", {
            method: "POST",
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).catch();

        await cpsResponse.text();
        return cpsResponse.ok;

    } else {
        let response = await fetch("/users/check-push-optin", {
            method: "GET",
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(response => {
            return response.json();
        }).catch(() => {
          return;
        });

        if(response.check == true) {
            const pushNotificationsOptin = document.getElementById('pushNotificationsOptin');

            if(pushNotificationsOptin) {
                const pushOptIn = document.getElementById('pushOptIn');
                const pushOptOut = document.getElementById('pushOptOut');
                pushNotificationsOptin.classList.remove('hidden');
                pushNotificationsOptin.classList.remove('inactive');
                pushNotificationsOptin.classList.add('active');

                pushOptIn.addEventListener('click', (e) => {
                    pushNotificationsOptin.classList.remove('active');
                    pushNotificationsOptin.classList.add('inactive');
                    pushOptIn.disabled = true;
                    pushOptOut.disabled = true;
                    askPermission();
                });

                pushOptOut.addEventListener('click', (e) => {
                    pushNotificationsOptin.classList.remove('active');
                    pushNotificationsOptin.classList.add('inactive');
                    pushOptIn.disabled = true;
                    pushOptOut.disabled = true;
                    unsubscribePush();
                });
            }
        } else {
            return;
        }
    }
}

async function unsubscribePush() {
    navigator.serviceWorker.ready
      .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
      .then(subscription => {
        // Check that we have a subscription to unsubscribe
        if (!subscription || subscription==null) {
            fetch("/users/opt-out-web-push", {
                method: "POST",
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then((response) => {
                return response.text();
            });
        } else {
            subscription.unsubscribe();

            let formData = new FormData();

            formData.append('subscription', subscription.endpoint);

            fetch("/users/unsubscribe-push", {
                method: "POST",
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then(response => {
                return response.text();
            });
        }
      })
      .catch(e => {
        // We failed to unsubscribe, this can lead to
        // an unusual state, so  it may be best to remove
        // the users data from your data store and
        // inform the user that you have done so
        console.error('Error when unsubscribing the user', e);
      });
}

function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


export default Push;