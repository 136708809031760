class Offers {

    constructor() {

        const storeLocationAddressCheckbox = document.querySelector('.form.content #storeLocationAddressChange');

        if(storeLocationAddressCheckbox) {
            this.checkAddressEdit();
            this.addCheckAddressListener();
        }
    }

    checkAddressEdit() {
        const storeLocationAddressCheckbox = document.querySelector('.form.content #storeLocationAddressChange');

        if(storeLocationAddressCheckbox.checked) {
            const storeAddressContainer = document.querySelector('.form.content #storeAddressContainer');
            storeAddressContainer.style.display = 'block';
        }
    }
    addCheckAddressListener() {
        const storeLocationAddressCheckbox = document.querySelector('.form.content #storeLocationAddressChange');
        const storeAddressContainer = document.querySelector('.form.content #storeAddressContainer');

        storeLocationAddressCheckbox.addEventListener('click', () => {
            let show = storeLocationAddressCheckbox.checked ? 'block' : '';
            storeAddressContainer.style.display = show;
        });
    }
}
export default Offers;